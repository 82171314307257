import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { AppBar, Box, Button, CircularProgress, Badge, Checkbox, Grid, Drawer, Tooltip, FormHelperText, TextField, Divider, Dialog, DialogTitle, DialogContent, Link, Toolbar, ButtonBase, Avatar, Popover, Typography, ListItemIcon, MenuItem, ListItemText } from '@material-ui/core';
import CogIcon from '../icons/Cog';
import UserIcon from '../icons/User';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import TwitterIcon from '@material-ui/icons/Twitter';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CheckIcon from "../icons/Check";
import toast from 'react-hot-toast';

import useAuth from '../hooks/useAuth';
import useMounted from '../hooks/useMounted';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useEagerConnect from '../hooks/useEagerConnect';
import useInactiveListener from '../hooks/useInactiveListener';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { experimentalStyled, alpha } from '@material-ui/core/styles';
import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector';
import { Web3Provider } from '@ethersproject/providers';
import { formatEther } from '@ethersproject/units';
import { injected } from '../services/WalletConnectors';
import API from '../services/API';
import Scrollbar from './Scrollbar';
import { addressFormatted } from '../pages/create/Minting';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment/moment';

const typeOptions = [
  {
    description: 'MetaMask is a bridge that allows you to visit the distributed web of tommorow in your browser today.',
    title: 'MetaMask',
    value: 'metamask',
    image: 'metamask.svg'
  },
  {
    description: 'WalletLink by Coinbase establishes a secure bridge between your Coinbase Wallet and desktop browser. To connect, all you need to do is scan the QR code with your Coinbase Wallet app.',
    title: 'Coinbase Wallet',
    value: 'wallet_link',
    image: 'coinbase.svg'
  },
  {
    description: 'Connect your Ledger device through Metamask. Ledger hardware wallets are a series of multi-currency wallets that are used to store private keys for cryptocurrencies offline.',
    title: 'Ledger (via MetaMask)',
    value: 'ledger',
    image: 'ledger-logo.png'
  }
];

const MainNavbarRoot = experimentalStyled('div')(({ theme }) => ({
  '& .navigation-link': {
    '&:hover': {
      color: '#2ECC71',
      transition: 'all 0.1s ease-in'
    },
    color: theme.palette.text.secondary,
    textDecoration: 'none'
  }
}));

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Top level Pages (Navigation)
const navigationSections = [
  // {
  //   title: 'Explore',
  //   path: '/explore',
  //   showGuest: true,
  //   showMobile: true
  // },
  {
    title: 'Create',
    path: '/create',
    showGuest: false,
    showMobile: true,
  }
];

const MainNavbar = (props) => {

  const isLive = moment().isAfter(moment(process.env.REACT_APP_LAUNCH_TIME * 1000));

  // isMounted
  const mounted = useMounted();

  // Navigation hook
  const navigate = useNavigate();

  // Router Location
  const routerLocation = useLocation();
  const isPartialMatch = (_p) => {
    // Check if route is a partial match on path
    return (_p) ? !!matchPath({
      path: _p
    }, routerLocation.pathname) : false;
  };

  const lightMode = (routerLocation.pathname.includes('/item/'));

  // User Auth
  const { user, login, logout, isInitialized, isAuthenticated } = useAuth();

  // Dropdown Menu (Popover)
  const anchorRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const handleOpen = () => {
    setPopoverOpen(true);
  };
  const handleClose = () => {
    setPopoverOpen(false);
  };

  // Device Size
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  // useWeb3React (Wallet State in Context)
  const { active, account, library, chainId, connector, activate, deactivate, error } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();

  // New User Onboarding Flow
  const [newUserStep, setNewUserStep] = useState(0);

  // Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerClose = (ev) => {
    setDrawerOpen(false);
  };
  const handleDrawerClick = (ev) => {
    setDrawerOpen(true);
  };

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // Crypto Utilities
  async function connectWallet() {
    try {
      console.log("CONNECT WALLET", injected);
      await activate(injected);
      await timeout(2000);
    } catch (ex) {
      console.log(ex);
    }
  }
  async function disconnectWallet() {
    try {
      deactivate();
      await timeout(600);
      toast.success('Wallet Disconnected!');
    } catch (ex) {
      console.log(ex);
    }
  }

  // Eager Connect (Wallet)
  const triedEager = useEagerConnect();
  const suppressEvents = (!triedEager || !!activatingConnector);

  // Track Auth Flow Step
  const [authFlowStep, setAuthFlowStep] = useState(0);

  // Step #0 - Clicking Start
  const clickStart = () => {

    // No Wallet? (window.ethereum is null) - Trigger Guide to Setup Metamask
    if (!window.ethereum) {
      setNewUserStep(1);
    }

    // Trigger Connect Wallet with Wallet Provider
    connectWallet();
  };

  useEffect(() => {
    if (routerLocation.pathname.includes('/create') && !isMobileDevice && newUserStep === 0 && !active && !account) {
      clickStart();
    }
  }, [routerLocation]);

  // Auth Flow Step #1 Action - Signup
  const signup = async (email, tosAndPrivacySigned) => {

    if (!tosAndPrivacySigned) {
      return;
    }

    // Attempt to signup to backend, with email
    try {

      const resp = await API.post('user/signup', {
        email,
        tos_and_privacy_signed: true
      });

      if (resp && resp.data) {
        // Persist to localStorage in case we refresh
        window.localStorage.setItem("user", JSON.stringify(resp.data));
        setAuthFlowStep(2);
      }

    } catch (err) {
      toast.error("Something went wrong.");
    }
  };

  // Auth Flow Step #2 Action - Sign Verification
  const signVerification = async () => {

    if (!window.localStorage.getItem('user') || !account) {
      toast.error("Something went wrong.");
      return;
    }

    // Get the nonce from signup attempt
    const parsed = JSON.parse(window.localStorage.getItem('user'));
    const { nonce, _id, email } = parsed;

    // Get the user as a signer (from library [ethers], instantiated from Web3ReactProvider in index.js)
    const signer = library.getSigner();

    // Sign a message
    const sign = await signer.signMessage(`To verify your Machinations account, sign below.`);

    if (sign) {

      const resp = await API.patch('/user/signup', {
        id: _id,
        sign,
        address: account.toLowerCase(),
        email
      });

      const ensName = await addressFormatted(account);
      if (ensName) {
        window.localStorage.setItem('ens', ensName);
      }

      if (resp && resp.data) {

        login(resp.data.token, resp.data.user);

        // Reset Auth Flow
        setAuthFlowStep(0);

        // Navigate to /create
        navigate('/create');

        toast.success("Success! Welcome to Machinations.");

      }
    }
  };

  // Sign Verification - Re-Authenticate Flow
  const signVerificationLogin = async () => {

    if (!account) {
      toast.error("Something went wrong.");
      return;
    }

    // Get the user as a signer (from library [ethers], instantiated from Web3ReactProvider in index.js)
    const signer = library.getSigner();

    // Sign a message
    const sign = await signer.signMessage(`To verify your Machinations account, sign below.`);

    if (sign) {

      const resp = await API.post('/user/login', {
        sign,
        address: account.toLowerCase()
      });

      const ensName = await addressFormatted(account);
      if (ensName) {
        window.localStorage.setItem('ens', ensName);
      }

      if (resp && resp.data) {

        login(resp.data.token, resp.data.user);

        // Reset Auth Flow
        setAuthFlowStep(0);

        toast.success("Re-authentication success!");

      }
    }
  };

  // Wallet Activation Handler (account is address from Metamask)
  useEffect(() => {

    if (account && !isAuthenticated) {

      // Make sure we've set address
      if (!window.localStorage.getItem('address') || window.localStorage.getItem('address') !== account) {
        window.localStorage.setItem('address', account);
      }

      // Check if user exists
      API.get(`user/user?address=${account}`).then((resp) => {

        // Signature Re-verification (User exists, Wallet connected, Token not present)
        if (!window.localStorage.getItem('token')) {
          setAuthFlowStep(3);
        }

      }).catch((err) => {

        if (err.response && err.response.status === 404) {
          // User does not exist.  Kick off Auth Flow
          setTimeout(() => {
            setAuthFlowStep(1);
          }, 1000);
        }
      });
    }
  }, [account]);

  // Handle injected ethereum events
  useEffect(() => {

    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppressEvents) {

      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };

      const handleChainChanged = (_chain) => {
        console.log("Handling 'chainChanged' event with payload", _chain);
        activate(injected);
      };

      const handleAccountsChanged = (accounts) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
          navigate('/create');
        }
      };

      const handleNetworkChanged = (networkId) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        activate(injected);
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('networkChanged', handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppressEvents, activate]);

  // Make sure we always trigger connect flow when navigating to /create
  // useEffect(() => {

  //   // Catch Location Update
  //   if (routerLocation.pathname === "/create") {
  //     clickStart();
  //   }

  // }, [routerLocation.pathname]);

  const getDialogTitle = (step) => {
    if (step === 1) {
      return "Create Your Account";
    } else if (step === 2) {
      return "Sign Verification Challenge";
    } else if (step === 3) {
      return "Re-verify Wallet Address";
    } else {
      return "";
    }
  };

  const browserConfig = () => {

    // Internet Explorer 6-11
    const isIE = false || !!document.documentMode;
    // Chrome 1 - 71
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';
    // Brave
    const isBrave = (navigator.brave);

    if (isChrome || isBrave) {
      return {
        store: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
        message: 'Get Metamask Chrome Extension'
      };
    } else if (isFirefox) {
      return {
        store: 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
        message: 'Get Metamask Firefox Add-On'
      };
    } else if (isEdge) {
      return {
        store: 'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US',
        message: "Get Metamask Edge Add-On"
      };
    } else {
      return {
        store: null,
        message: "Unsupported Browser"
      };
    }
  };

  // New User Flow - Open MetaMask Extension download link in new Tab and advance Step
  const handleMetamaskDownload = (ev) => {
    window.open(browserConfig().store);
    setNewUserStep(2);
  };

  // New User Flow - Close out Dialog and re-prompt Sign-Up / Auth flow Start
  const handleMetamaskDownloadDone = (ev) => {
    setNewUserStep(0);
    clickStart();
  };

  const getDialogContent = (step) => {

    if (step === 1) {

      // Signup Step #1 - Create Temp Account
      return (
        <>
          <Box style={{ minWidth: '300px', textAlign: 'center', padding: '2rem', paddingTop: '1rem' }}>
            <Typography variant="subtitle">hey there! let&#39;s get you started.</Typography>
            <Formik
              initialValues={{
                tos_and_privacy_signed: false,
                submit: null
              }}
              validationSchema={Yup
                .object()
                .shape({
                  tos_and_privacy_signed: Yup
                    .boolean()
                    .oneOf([true], 'This field must be checked')
                })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  await signup("test@test.com", values.tos_and_privacy_signed);

                  if (mounted.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                  }
                } catch (err) {
                  console.error(err);
                  if (mounted.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, dirty, isValid, values }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  {...props}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      ml: -1,
                      mt: 2
                    }}
                  >
                    <Checkbox
                      checked={values.tos_and_privacy_signed}
                      color="primary"
                      name="tos_and_privacy_signed"
                      onChange={handleChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      I have read, and I agree to the
                      {' '}
                      <Link
                        color="primary"
                        component="a"
                        href="/tos"
                        target="_blank"
                      >
                        Terms of Use
                      </Link>
                      {' and '}
                      <Link
                        color="primary"
                        component="a"
                        href="/privacy"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Box>
                  {errors.submit && (
                    <Box sx={{ mt: 0 }}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                  )}
                  <Box sx={{ mt: 2, mb: 0 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Continue
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </>
      );
    } else if (step === 2) {
      // Signup Step #2 - Signature Verification (as part of Signup)
      return (
        <>
          <Box style={{ textAlign: 'center', paddingLeft: '1rem', paddingRight: '1rem' }}>
            <Typography variant="subtitle">Click the button below to sign a verification challenge via metamask proving your ownership of this wallet.</Typography>
            <Box sx={{ mt: 4, mb: 4 }}>
              <Button
                color="primary"
                fullWidth
                onClick={signVerification}
                size="large"
                type="submit"
                variant="contained"
              >
                Open Verification
              </Button>
            </Box>
          </Box>
        </>
      );
    } else if (step === 3) {
      // Signature Re-verification (User exists, Wallet connected, Token not present)
      return (
        <>
          <Box style={{ textAlign: 'center' }}>
            <Box sx={{ mt: 3, mb: 4, textAlign: 'center' }}>
              <span style={{ padding: '8px', backgroundColor: 'grey', color: 'white', borderRadius: '8px' }}>{account && (account)}</span>
            </Box>
            <Typography variant="subtitle">Welcome back! For your security, we need to re-verify the wallet address linked to your account with a signature.</Typography>
            <Box sx={{ mt: 2, mb: 4 }}>
              <Button
                color="primary"
                fullWidth
                onClick={signVerificationLogin}
                size="large"
                type="submit"
                variant="contained"
              >
                Open Verification
              </Button>
            </Box>
          </Box>
        </>
      );
    }

    return null;
  };

  const ens = window.localStorage.getItem('ens');

  return (
    <MainNavbarRoot>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: (lightMode) ? '#6e6e6e' : 'background.paper',
          color: (lightMode) ? 'white' : 'text.secondary'
        }}
      >
        {(isLive === false && mdUp) && (
          <Toolbar sx={{ backgroundColor: '#333333', textAlign: 'center', width: '100%' }}>
            <Grid container style={{ width: '100%' }} justifyContent="center" spacing={2}>
              <Grid item>
                <WarningIcon />
              </Grid>
              <Grid item>
                <Typography>
                  Minting is not yet live.
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        )}
        <Toolbar sx={{ minHeight: 64, padding: '1rem' }}>
          <RouterLink to="/">
            {mdUp ? (
              <img
                alt="logo"
                src="/static/text_logo.png"
                style={{
                  height: '24px',
                  marginLeft: '0.2rem',
                  marginTop: '3px'
                }}
              />
            ) : (
              <img
                alt="logo"
                src="/static/white_icon.png"
                style={{
                  height: '30px',
                  marginLeft: '0.4rem',
                  marginTop: '7px'
                }}
              />
            )}
          </RouterLink>
          {mdUp && (
            <>
              <Box style={{ width: '0.75rem', height: '0.75rem', backgroundColor: (isLive) ? '#2ECC71' : 'orange', borderRadius: '50%', marginLeft: '1.6rem' }} />
              <Typography style={{ fontSize: '1rem', color: 'white', marginLeft: '0.55rem', paddingBottom: '1px' }}>{isLive ? "Live (Mainnet)" : "Pre-Launch"}</Typography>
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {/* <Box style={{ marginRight: '0.5rem' }}>
            <Button
              component="a"
              href={process.env.REACT_APP_TWITTER_LINK}
              target="_blank"
              size="large"
              variant="text"
              rel="noreferrer"
              sx={{
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(248,248,248,0.1)'
                },
                marginTop: '3px',
                marginRight: (isLive) ? '1rem' : '0px'
              }}
            >
              {mdUp ? (
                <>
                  <img src="/static/twitter_white.png" alt="discord" style={{ height: '20px', width: '20px', marginRight: '6px' }} />
                  <span>
                    Follow our Twitter
                  </span>
                </>
              ) : (
                <span>Twitter</span>
              )}
            </Button>
          </Box> */}
          <Box style={{ marginRight: '2rem' }}>
            <Button
              component="a"
              href={process.env.REACT_APP_DISCORD_LINK}
              target="_blank"
              size="large"
              variant="text"
              rel="noreferrer"
              sx={{
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(248,248,248,0.1)'
                },
                marginTop: '0px'
              }}
            >
              {mdUp ? (
                <>
                  <img src="/static/discord.png" alt="discord" style={{ height: '20px', width: '20px', marginRight: '12px' }} />
                  <span style={{ fontFamily: 'Roboto Mono' }}>
                    Join our Discord
                  </span>
                </>
              ) : (
                <>
                  <img src="/static/discord.png" alt="discord" style={{ height: '20px', width: '20px', marginRight: '12px' }} />
                  <span style={{ fontFamily: 'Roboto Mono' }}>
                    Discord
                  </span>
                </>
              )}
            </Button>
          </Box>
          <>
            {/* <Box style={{ paddingRight: (mdUp ? '2.3rem' : '1.5rem'), marginBottom: '2px' }}>
              <Tooltip title="FAQ (sidebar)">
                <ButtonBase onClick={handleDrawerClick}>
                  <Typography
                    variant="h5"
                    sx={{
                      textDecoration: 'none',
                      textTransform: 'uppercase',
                      '&:hover': {
                        color: 'white'
                      },
                      ...(drawerOpen && {
                        color: 'white',
                        paddingBottom: '4px',
                        borderBottom: '1px solid white',

                      })
                    }}
                    style={{
                      fontSize: '1rem',
                      transition: 'all 0.1s ease-in-out'
                    }}
                  >
                    <InfoIcon style={{ marginTop: '8px' }} />
                  </Typography>
                </ButtonBase>
              </Tooltip>
            </Box> */}
            {navigationSections && navigationSections.map((navigationSection) => (
              <>
                {(navigationSection.showMobile === true || mdUp) && ((user && active) || navigationSection.showGuest === true) && (
                  <Box style={{ paddingRight: (mdUp ? '2.3rem' : '1.5rem') }}>
                    <RouterLink to={navigationSection.path} className="navigation-link">
                      <Typography
                        variant="h5"
                        sx={{
                          textDecoration: 'none',
                          textTransform: 'none',
                          color: (lightMode) ? 'white' : 'text.secondary',
                          '&:hover': {
                            color: 'white'
                          },
                          ...(isPartialMatch(navigationSection.path) && {
                            color: 'white',
                            paddingBottom: '4px',
                            borderBottom: '1px solid white',

                          })
                        }}
                        style={{
                          fontSize: '1rem',
                          transition: 'all 0.1s ease-in-out'
                        }}
                      >
                        {navigationSection.title}
                      </Typography>
                    </RouterLink>
                  </Box>
                )}
              </>
            ))}
            {mdUp && (
              <>
                <Box>
                  <>
                    {!isInitialized && (
                      <CircularProgress size={18} style={{ marginTop: '6px' }} />
                    )}
                  </>
                </Box>
                <Box>
                  {(user && active) ? (
                    <>
                      <Tooltip title="Profile & Collection">
                        <Box
                          component={ButtonBase}
                          onClick={handleOpen}
                          ref={anchorRef}
                          disableRipple
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: '3px'
                          }}
                        >
                          <Badge color={active ? "success" : "error"} overlap="circular" variant="dot" badgeContent=" ">
                            <Avatar
                              src={user.avatar}
                              sx={{
                                height: 32,
                                width: 32,
                                backgroundColor: (lightMode) ? 'white' : 'text.secondary',
                                '&:hover': {
                                  backgroundColor: 'white',
                                  transition: 'all 0.1s ease-in'
                                }
                              }}
                            />
                          </Badge>
                        </Box>
                      </Tooltip>

                      <Popover
                        anchorEl={anchorRef.current}
                        anchorOrigin={{
                          horizontal: 'center',
                          vertical: 'bottom'
                        }}
                        keepMounted
                        onClose={handleClose}
                        open={popoverOpen}
                        PaperProps={{
                          sx: { width: 240, backgroundColor: 'rgb(18, 18, 18)' }
                        }}
                        elevation={12}
                      >
                        <Box sx={{ p: 2 }}>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            style={{ fontSize: '1.1rem', fontFamily: 'Roboto Mono' }}
                          >
                            {(ens) ? (
                              <>
                                {ens}
                              </>
                            ) : (
                              <>
                                {account && `${account.substring(0, 9)}...${account.substring(account.length - 4)}`}
                              </>
                            )}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            style={{ fontSize: '0.8rem' }}
                          >
                            {(user.email === 'test@test.com') ? 'Artist, Collector' : user.email}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 1, mb: 2 }}>
                          <MenuItem
                            component={RouterLink}
                            to={`/profile/${account}`}
                            onClick={handleClose}
                          >
                            <ListItemIcon>
                              <AccountBalanceWalletIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={(
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Collection (Profile)
                                </Typography>
                              )}
                            />
                          </MenuItem>
                          <MenuItem
                            component={RouterLink}
                            to="/minted"
                            onClick={handleClose}
                          >
                            <ListItemIcon>
                              <CompareArrowsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={(
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Minted Transactions
                                </Typography>
                              )}
                            />
                          </MenuItem>
                          {/* <MenuItem
                        component={RouterLink}
                        to="/dashboard/account"
                      >
                        <ListItemIcon>
                          <CogIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={(
                            <Typography
                              color="textPrimary"
                              variant="subtitle2"
                            >
                              Settings
                            </Typography>
                          )}
                        />
                      </MenuItem> */}
                        </Box>
                        {/* <Box sx={{ p: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        onClick={() => console.log("CLICK LOGOUT")}
                        variant="outlined"
                      >
                        Logout
                      </Button>
                    </Box> */}
                      </Popover>
                    </>
                  ) : (
                    <>
                      {isInitialized && (
                        <Button
                          onClick={clickStart}
                          size="large"
                          variant="contained"
                        >
                          START
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
          </>
        </Toolbar>
      </AppBar>
      <Dialog
        open={authFlowStep && authFlowStep > 0}
        disableEscapeKeyDown={false}
      >
        <DialogTitle id="responsive-dialog-title">
          {getDialogTitle(authFlowStep)}
        </DialogTitle>
        <DialogContent>
          {getDialogContent(authFlowStep)}
        </DialogContent>
      </Dialog>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Box style={{ width: '650px', maxWidth: '85vw', textAlign: 'center', paddingTop: '2rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
          <Scrollbar>
            <Typography style={{ fontFamily: 'Roboto Mono', fontSize: '2rem', fontWeight: 'bold' }}>FAQ</Typography>
            <Box style={{ textAlign: 'left' }}>
              <Box sx={{ p: 2 }}>
                <Typography style={{ fontFamily: 'Roboto Mono', fontSize: '1.2rem' }}>Q: What is machinations?</Typography>
                <Box style={{ marginTop: '3px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '4px' }} variant="subtitle">
                    Machinations lets you mint generative AI art on demand, stored immutably on the Ethereum blockchain. However, unlike other NFT projects, you are in control—all 10,000 items of this collection will be generated by the prompts you submit to our AI art engine. If you create an art piece that you like, just mint and reserve your prompt and generated artwork.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography style={{ fontFamily: 'Roboto Mono', fontSize: '1.2rem' }}>Q: How does machinations work?</Typography>
                <Box style={{ marginTop: '3px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '4px' }} variant="subtitle">
                    Our AI art is created by a Generative Adversarial Network (GAN) that is fed selective images from datasets of millions of captioned images by a companion natural-language parsing neural network called CLIP.  Using only a phrase or a sentence, these neural networks allow anyone to generate completely unique images.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography style={{ fontFamily: 'Roboto Mono', fontSize: '1.2rem' }}>Q: What are modifiers, and why should I use them?</Typography>
                <Box style={{ marginTop: '3px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '4px' }} variant="subtitle">
                    Modifiers can be added to your prompt to influence the AI model&apos;s image generation.  Adding key words to influence the desired style of the image can dramatically shift the output in unexpected and interesting ways.
                  </Typography>
                </Box>
                <Box style={{ marginTop: '10px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '12px' }} variant="subtitle">
                    For example, for the prompt:
                    {'  '}
                    <b>&quot;WASTELAND OF THE MIND&quot;</b>
                  </Typography>
                </Box>
                <Box style={{ width: '100%', textAlign: 'center' }}>
                  <Box style={{ marginTop: '1.75rem', marginBottom: '1.5rem' }}>
                    <img src="/static/home/modifier_3.webp" alt="explanation 1" style={{ width: '100%' }} />
                  </Box>
                </Box>
                <Box style={{ marginTop: '8px', marginBottom: '12px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '4px' }} variant="subtitle">
                    Here are some modifiers you can try out: 8k resolution, 3840 x 2160, pencil sketch, 8k 3D, deviantart, unreal engine, concept art, photoillustration, filmic, pixiv, made of cheese, impressionism, ukiyo-e, flemish baroque, monet, ...
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography style={{ fontFamily: 'Roboto Mono', fontSize: '1.2rem' }}>Q: What about rarity?</Typography>
                <Box style={{ marginTop: '3px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '4px' }} variant="subtitle">
                    All user-submitted prompts can only be reserved once. Each finished artwork is stamped with a unique serial ID from 0 to 9,999.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography style={{ fontFamily: 'Roboto Mono', fontSize: '1.2rem' }}>Q: How does upvoting artwork work?</Typography>
                <Box style={{ marginTop: '3px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '4px' }} variant="subtitle">
                    Only minters who have created an art piece can upvote other items.  Upvoted items are shown on the &quot;TOPLIST&quot; tab on the Home and Explore pages.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ p: 2, mb: 6 }}>
                <Typography style={{ fontFamily: 'Roboto Mono', fontSize: '1.2rem' }}>Q: Where is the artwork stored?</Typography>
                <Box style={{ marginTop: '3px' }}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: '1rem', marginTop: '4px' }} variant="subtitle">
                    On IPFS, a decentralized storage network.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Scrollbar>
        </Box>
      </Drawer>
      <Dialog
        open={newUserStep > 0}
        disableEscapeKeyDown={false}
      >
        <DialogTitle id="responsive-dialog-title">
          Install Metamask
        </DialogTitle>
        <DialogContent>
          <>
            <Box style={{ textAlign: 'center' }}>
              {/* <Box sx={{ mt: 2, mb: 2 }} style={{ textAlign: 'center' }}>
                <img src="/static/crypto.png" alt="nft wallet" style={{ maxHeight: '100px', opacity: 0.75 }} />
              </Box> */}
              <Box>
                <Typography variant="subtitle">The first thing you will need is a secure wallet to store your NFT&apos;s.  This wallet (once installed on your browser) will allow you to sign in to the Machinations application (or any other &quot;dApp&quot;) without a password.</Typography>
              </Box>
              {newUserStep === 1 && (
                <Box sx={{ mt: 4, mb: 4 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    onClick={handleMetamaskDownload}
                    disabled={(browserConfig().store === null)}
                    variant="contained"
                  >
                    {browserConfig().message}
                  </Button>
                </Box>
              )}
              {newUserStep === 2 && (
                <Box sx={{ mt: 4, mb: 4 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    onClick={handleMetamaskDownloadDone}
                    variant="contained"
                  >
                    All Done
                  </Button>
                </Box>
              )}
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </MainNavbarRoot>
  );
};

export default MainNavbar;
