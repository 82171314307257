import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL || "http://localhost:8080/";

const apiService = axios.create({
  baseURL: apiURL,
  responseType: 'json'
});

// Axios Instance
export default apiService;

export { apiURL };
