import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import API from '../services/API';
import mixpanel from 'mixpanel-browser';
import toast from 'react-hot-toast';

const initialState = {

  // isInitialized (have we finished initializing yet)
  isInitialized: false,

  // isAuthenticated (email + wallet)
  isAuthenticated: false,

  // User Object
  user: null

};

const handlers = {

  // On Load
  INITIALIZE: (state, action) => {
    const { user, isAuthenticated } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      user
    };
  },

  // Login
  LOGIN: (state, action) => {
    const { user } = action.payload;
    mixpanel.track('LOGIN');
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },

  // Logout
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: null,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {

  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {

    // Assign Anonymous ID, if doesn't exist
    const guestId = window.localStorage.getItem('guest_id');
    if (!guestId) {
      window.localStorage.setItem('guest_id', uuidv4());
    }

    // Check if Token exists in localStorage.  If so, assume we are authenticated (TODO: handle expiration)
    const token = window.localStorage.getItem('token');
    const isAuthenticated = !!token;

    if (isAuthenticated) {
      try {
        const userData = await API.get('user/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (userData && userData.data) {
          try {
            gtag('config', 'G-FYFDBDRTDS', { 'user_id': userData.data['_id'] });
            mixpanel.identify(userData.data['_id']);
            mixpanel.people.set({ ...userData.data });
          } catch (err) {
            console.error(err);
          }
        }
        dispatch({
          type: 'INITIALIZE',
          payload: {
            user: userData?.data,
            isAuthenticated: true
          }
        });
      } catch (err) {
        const code = err?.response?.status;
        if (code && code === 404) { // Edge Case - User deleted from backend
          window.localStorage.clear();
          window.location.reload();
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              user: null,
              isAuthenticated: false
            }
          });
        }
      }
    } else {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user: null,
          isAuthenticated: false
        }
      });
    }
  };

  useEffect(() => {

    initialize();

  }, []);

  const login = async (token, user) => {

    if (token && user) {

      // Save to LocalStorage
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      // Update State
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    }
  };

  const logout = async () => {
    window.localStorage.clear();
    toast.success("Logged out");
    // window.reload();
    dispatch({
      type: 'LOGOUT'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
