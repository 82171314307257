import ContractABI from './ContractABI';
import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import { ethers } from 'ethers';

const alchemyURL = process.env.REACT_APP_ALCHEMY_URL;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

export const addressFormatted = async (address) => {
  if (!address) {
    return "...";
  } else {
    const isAddress = (address.startsWith('0x') && address.length >= 40);
    if (isAddress) {
      const web3EthersProvider = new ethers.providers.JsonRpcProvider(alchemyURL);
      const name = await web3EthersProvider.lookupAddress(address);
      return name || `${address.substring(0, 4)}...${address.substring(address.length - 4)}`;
    } else {
      return address;
    }
  }
};

export const mintNFT = async (address, whitelistData, mintPriceData) => {

  const web3 = createAlchemyWeb3(alchemyURL);

  // Set contract onto window
  window.contract = await new web3.eth.Contract(ContractABI, contractAddress);

  const { price, unit } = mintPriceData;

  let transactionParameters = null;
  if (whitelistData && whitelistData.current_allowance && whitelistData.current_allowance > 0) {
    // Whitelist Mint
    const { proof, allowance } = whitelistData;
    transactionParameters = {
      to: contractAddress,
      from: address,
      'data': window.contract.methods.whitelistMint(proof, allowance).encodeABI(),
      'value': web3.utils.toHex(web3.utils.toWei("0", unit))
    };
  } else {
    // Normal Mint
    transactionParameters = {
      to: contractAddress,
      from: address,
      'data': window.contract.methods.mint(address).encodeABI(),
      'value': web3.utils.toHex(web3.utils.toWei(price.toString(), unit))
    };
  }

  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    });
    return txHash;
  } catch (err) {
    return null;
  }
};
