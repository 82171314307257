import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

// Sentry Performance & Error Monitoring
Sentry.init({
  dsn: "https://8a7c64eff8284867b16562dab450c157@o1084973.ingest.sentry.io/6125268",
  integrations: [new Integrations.BrowserTracing()],
  release: "machinations-client@1.0.0",
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.25, // TODO: drop this to 0.1 or 0.25
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <SettingsProvider>
          <BrowserRouter>
            <AuthProvider>
              <Web3ReactProvider getLibrary={getLibrary}>
                <App />
              </Web3ReactProvider>
            </AuthProvider>
          </BrowserRouter>
        </SettingsProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>, document.getElementById('root')
);
