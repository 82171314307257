import { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Privacy & TOS
const Privacy = Loadable(lazy(() => import('./pages/Privacy')));
const TOS = Loadable(lazy(() => import('./pages/TOS')));

// Other pages
const Home = Loadable(lazy(() => import('./pages/Home')));

// Creation Flow
const Creation = Loadable(lazy(() => import('./pages/create/Creation')));

// Explore
const Explore = Loadable(lazy(() => import('./pages/explore/Explore')));

// Snapshot Detail page
const SnapshotDetail = Loadable(lazy(() => import('./pages/snapshot/SnapshotDetail')));

// Collection Page
const Collection = Loadable(lazy(() => import('./pages/collection/Collection')));

// Minted Items Page
const Mints = Loadable(lazy(() => import('./pages/mints/Mints')));

// Minted Items Detail Page
const MintDetail = Loadable(lazy(() => import('./pages/mints/MintDetail')));

const routes = [
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/item/:id',
        element: <SnapshotDetail />
      },
      {
        path: '/profile/:walletAddress',
        element: <Collection />
      },
      {
        path: '/minted',
        element: <Mints />
      },
      {
        path: '/minted/:id',
        element: <MintDetail />
      },
      {
        path: '/explore',
        element: <Explore />
      },
      {
        path: 'create',
        element: <Creation />
      },
      {
        path: 'tos',
        element: <TOS />
      },
      {
        path: 'privacy',
        element: <Privacy />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
